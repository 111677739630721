<script setup lang="ts">
import { Link } from '@inertiajs/vue3'

type Props = {
    enteredEmail: string;
    enteredPassword: string
    rememberMeCheckbox: boolean
    isInvalidValidationClass?: string
}

const props = defineProps<Props>()

const emit = defineEmits([
    'submitModalLoginForm', 
    'updateEnteredEmail', 
    'updateEnteredPassword', 
    'updateRememberMeCheckbox'
])

</script>

<template>
    <form
        class="form-validate two-fields-login-form" 
        @submit.prevent="emit('submitModalLoginForm')"
    >
        <div class="two-fields-login-form-body">
            <div class="form-group">
                <label for="userEmail" class="form-label">Your best Email Address</label>
               
                <input
                    id="userEmail"
                    :value="enteredEmail"
                    autocomplete="off"
                    type="email"
                    :class="isInvalidValidationClass"
                    placeholder="Your best Email Address"
                    required
                    class="form-control"
                    autofocus
                    @input="emit('updateEnteredEmail', $event)"
                >
            </div>

            <div class="form-group mb-4">
                <div class="row">
                    <div class="col">
                        <label for="userPassword" class="form-label">Password</label>
                    </div>
                    <div class="col-auto">
                        <Link href="/login/forgot" class="form-text small">
                            Forgot password?
                        </Link>
                    </div>
                </div>
               
                <input
                    id="userPassword"
                    :value="enteredPassword"
                    autocomplete="off"
                    placeholder="Password"
                    type="password"
                    required
                    class="form-control"
                    :class="isInvalidValidationClass"
                    @input="emit('updateEnteredPassword', $event)"
                >
            </div>

            <div class="form-group mb-4">
                <div class="custom-control custom-checkbox">
                    <input
                        id="userLoginRemember"
                        type="checkbox"
                        :checked="rememberMeCheckbox"
                        class="custom-control-input"
                        @change="emit('updateRememberMeCheckbox', $event)"
                    >
                    <label for="userLoginRemember" class="custom-control-label two-fields-login-form-remember"> <span class="text-sm">Remember me</span></label>
                </div>
            </div>

            <button class="btn btn-lg btn-block btn-primary" type="submit">
                Log In
            </button>
        </div>
        <!-- <slot /> -->
    </form>
</template>

<style lang="scss" scoped>

.two-fields-login-form {
    overflow: hidden;

    &-body {
        padding: 16px;
    }

    &-remember {
        color: var(--main-text);
    }
}
</style>